import "./App.scss";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Inventory from "./pages/inventory/Inventory";
import Bill from "./components/Bill";
import Expenses from "./pages/expenses/Expenses";
import Settings from "./pages/settings/Settings";
import Navbar from "./components/Navbar";
// import HotelStatus from "./components/HotelStatus";
import Login from "./pages/login/Login";
import ForgotPass from "./pages/login/ForgotPass";
import ResetPass from "./pages/login/ResetPass";
import RequestPopUp from "./components/requestPopup/RequestPopUp";
import ResProtectedRoutes from "./services/components/ResProtectedRoutes";
import { onMessageListener, requestForToken } from "./firebase";
import Notification from "./Notification";
import { Button } from "@mui/material";
import apis from "./components/axios/axios";
import Notifications from "./pages/notifications/Notifications";
import sound from "./sound/pop-39222.mp3";

function App() {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [notif, setNotif] = useState(false);
  // let [show, setShow] = useState(false);

  useEffect(() => {
    window.Notification.requestPermission().then(function(permission) { console.log('permission', permission)});
  }, []);

  useEffect(() => {
    if (notification?.title) {
      setNotif(true);
    }
  }, [notification]);

  // let Popup = () => {
  //   console.log(notification)
  //   return (
  //     <div className="request-popup-main">
  //       <div className="request-popup">
  //         <div className="popup-text-left">Order Type </div>
  //         <div className="popup-text-left">: {notification?.body["type"]}</div>
  //         <div className="popup-text-left">No. of Guest </div>
  //         <div className="popup-text-left">: {notification?.body["guests"]}</div>
  //         <div className="popup-text-left">Time </div>
  //         <div className="popup-text-left">: {notification?.body["time"]}</div>
  //         <Button
  //           color="error"
  //           variant="contained"
  //           onClick={() => setShow(false)}
  //         >
  //           Decline
  //         </Button>
  //         <Button
  //           color="success"
  //           variant="contained"
  //           onClick={() => setShow(false)}
  //         >
  //           Accept
  //         </Button>
  //       </div>
  //     </div>
  //   );
  // };

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload?.notification?.title,
        body: JSON.parse(payload?.notification?.body),
      });
      new Audio(sound).play();
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <div className="App">
      {/* {show && <Popup />} */}
      <BrowserRouter>
        <Navbar notif={notif} setNotif={setNotif} />
        <Routes>
          <Route element={<ResProtectedRoutes />}>
            <Route path="/" exact element={<Dashboard />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/invoice/:id" element={<Bill />} />
            <Route path="/expenses" element={<Expenses />} />
            <Route path="/notify" element={<Notifications />} />
            <Route path="/settings/*" element={<Settings />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/n" element={<Notification />} />
          <Route path="/forgot" element={<ForgotPass />} />
          <Route path="/reset" element={<ResetPass />} />
        </Routes>
      </BrowserRouter>
      {/* <button onClick={() => setRequestPopup(true)}>Open Popup</button>
      <RequestPopUp trigger={requestPopup} setTrigger={setRequestPopup} /> */}
    </div>
  );
}

export default App;
