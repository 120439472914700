import React, { useEffect, useState } from "react";
import "./HotelStatus.scss";
import { useLocation } from "react-router-dom";
import HotelStatusPopUp from "./HotelStatusPopUp";
import apis from "./axios/axios";

const HotelStatus = () => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    fetchRestaurant();
  }, []);

  let [restaurant, setRestaurant] = useState(null);

  let fetchRestaurant = async () => {
    let { data } = await apis.get(
      "restaurant/" + localStorage.getItem("jwt_res_id")
    );
    setRestaurant(data.restaurant);
  };

  let { pathname } = useLocation();
  if (
    pathname === "/login" ||
    pathname === "/invoice" ||
    pathname === "/reset" ||
    pathname === "/forgot"
  ) {
    return null;
  }

  if (!restaurant) return null;

  return (
    <div className="hotel-status-main">
      <div className="hotel-status-sub">
        <div>
          <h1 className="hotel-status-name">{restaurant.name}</h1>
          <p className="hotel-status-location">{restaurant.location}</p>
        </div>
        <div className="hotel-status-change">
          {localStorage.getItem("jwt_res_open") === "true" ? (
            <div className="hotel-working-status hotel-open">Open</div>
          ) : (
            <div className="hotel-working-status hotel-closed">Closed</div>
          )}
          <button onClick={() => setTrigger(true)}>Change</button>
        </div>
      </div>
      <HotelStatusPopUp trigger={trigger} setTrigger={setTrigger} />
    </div>
  );
};

export default HotelStatus;
