import React from "react";
import "./InventoryFoodPopUp.scss";
import img from "../../images/foodimg.jpeg";

const InventoryFoodPopUp = ({ trigger, setTrigger }) => {
  return trigger ? (
    <div className="inventory-popup-main">
      <div className="inventory-popup">
        <div
          onClick={() => setTrigger(false)}
          className="inventory-popup-close-btn"
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div>
          <div className="food-popup-img-div">
            <img className="food-popup-img" src={img} alt="" />
          </div>
          <div className="inventory-popup-food-name">Food Name</div>
          <div className="inventory-popup-food-details">
            <div>₹ 90(Non-AC)</div>
            <div>₹ 110(AC)</div>
            <div>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Perspiciatis assumenda ducimus repudiandae delectus cupiditate
              ratione id facere, harum fugit reprehenderit voluptatem
              praesentium eos aperiam? Libero quod facilis debitis in dolore?
              Vitae ex dolores illo iure, et voluptatibus eligendi possimus
              dolorem aut quidem aperiam alias, quod illum sit optio aspernatur
              laudantium culpa! Deserunt illo vel minus quibusdam vitae
              temporibus odit non? Deleniti ratione aliquid molestias vitae
              maxime expedita quos enim recusandae doloremque consequatur, illum
              praesentium non, aperiam perferendis error atque quaerat. Itaque
              impedit sed distinctio facilis sapiente voluptate quia in autem.
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default InventoryFoodPopUp;
