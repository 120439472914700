import React from "react";
// import Navbar from '../components/Navbar'
// import HotelStatus from '../components/HotelStatus'
import "./Dashboard.scss";
import Orders from "../components/Orders";
import HotelStatus from "../components/HotelStatus";

const Dashboard = () => {
  return (
    <div>
      {/* <Navbar />
        <HotelStatus /> */}
      <HotelStatus />
      <Orders />
    </div>
  );
};

export default Dashboard;
