import "./InventoryBottom.scss";
import InventoryFood from "./InventoryFood";
import React, { useEffect, useState } from "react";
// import { Switch } from "@mui/material";
import apis from "../../components/axios/axios";
// import InventoryFoodPopUp from "./InventoryFoodPopUp";

const InventoryBottom = () => {
  const [outOfStockButton, setStatus] = useState(false);
  const showOutofstocks = () => {
    setStatus(true);
  };
  const showAll = () => {
    setStatus(false);
  };

  useEffect(() => {
    fetchFood();
  }, []);

  let [food, setFood] = useState([]);

  let fetchFood = async () => {
    let { data } = await apis.get(
      "food/filter/restaurant/" + localStorage.getItem("jwt_res_id")+"?usertype=admin"
    );
    console.log(data.foods);
    setFood(data.foods);
  };

  return (
    <div className="inventory-main">
      <div className="inventory-sub">
        <div>
          <div className="inventory-category">
            <div className="inventory-category-heading">Categories</div>
            <div className="inventory-category-hr-main">
              <hr className="inventory-category-hr" align="left" />
            </div>

            {food &&
              food.map((e) => (
                <div key={e.id} className="inventory-category-sub">
                  <div>{e.category_name}</div>
                  <div>{e.foods.length}</div>
                </div>
              ))}
          </div>
        </div>

        <div>
          <div className="out-of-stock-btn-main">
            {outOfStockButton ? (
              <button
                onClick={showAll}
                className="out-of-stock-btn-active"
                type="submit"
              >
                <i className="fa-solid fa-circle-notch"></i> Out of stocks
              </button>
            ) : (
              <button
                onClick={showOutofstocks}
                className="out-of-stock-btn-inactive"
                type="submit"
              >
                <i className="fa-solid fa-circle-notch"></i> Out of stocks
              </button>
            )}
          </div>
          {food &&
            food.map((e) => (
              <div key={e.id} className="inventory-items-main">
                <div className="category-items-heading">
                  {e.category_name}({e.foods.length})
                </div>
                {e.foods.map((a, i) => {
                  if (outOfStockButton) {
                    if (a.is_available) return;
                    else
                      return (
                        <InventoryFood
                          key={i}
                          foodName={a.name}
                          id={a.id}
                          available={a.is_available}
                        />
                      );
                  } else
                    return (
                      <InventoryFood
                        key={i}
                        foodName={a.name}
                        id={a.id}
                        available={a.is_available}
                      />
                    );
                })}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default InventoryBottom;
