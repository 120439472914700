import React, { useState } from "react";
import "./Navbar.scss";
// import { useRef } from 'react'
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../images/Logo-nav.png";
import { Link, useLocation } from "react-router-dom";

const Navbar = ({ notif, setNotif }) => {
  const [viewNav, setViewNav] = useState(false);
  // const navRef = useRef();
  const showNavbar = () => {
    setViewNav(!viewNav);
    // navRef.current.classList.toggle("responsive-nav");
  };
  let { pathname } = useLocation();

  if (
    pathname === "/login" ||
    pathname.includes("/invoice/") ||
    pathname === "/reset" ||
    pathname === "/forgot"
  ) {
    return null;
  }
  return (
    <header>
      <Link to="/">
        <img className="nav-logo" src={logo} alt="" />
      </Link>
      <div className="nav">
        <nav className={viewNav ? "responsive-nav" : ""}>
          <Link className="nav-link" to="/">
            Orders
          </Link>
          <Link
            className="nav-link"
            to="/notify"
            style={{
              color: notif ? "red" : "inherit",
            }}
            onClick={() => setNotif(false)}
          >
            Notifications
          </Link>
          <Link className="nav-link" to="/inventory">
            Inventory
          </Link>
          <Link className="nav-link" to="/expenses">
            Expenses
          </Link>
          <Link className="nav-link" to="/settings/profile">
            Settings
          </Link>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </div>
    </header>

    // <div>
    //   <div className="navbar-nav">
    //     <div className="navbar-main-inside">
    //     <a href="/orders"><img className="nav-logo" src={logo} alt="" /></a>
    //       <div className="icon-flex">
    //           <div className="icon">
    //             <Link className="link" to="/orders">Orders</Link>
    //           </div>
    //           <div className="icon">
    //             <Link className="link" to="/inventory">Inventory</Link>
    //             {/* <a href="/inventory">Inventory</a> */}
    //           </div>
    //           <div className="icon">
    //             <Link className="link" to="/expenses">Expenses</Link>
    //             {/* <a href="/expenses">Expenses</a> */}
    //           </div>
    //           <div className="icon">
    //             <Link className="link" to="/settings">Settings</Link>
    //             {/* <a href="/settings">Settings</a> */}
    //           </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="nav-space"></div>
    // </div>
  );
};

export default Navbar;
