import React, { useEffect, useState } from 'react'
import apis from '../../components/axios/axios'
import AddItem from './AddItem'
import EditItem from './EditItem'
import './MenuChange.scss'

const MenuChange = () => {
    const [addFlag, setAddFlag] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const checkAddFlag = () => (
        addFlag?setAddFlag(false):null
    )
    const checkEditFlag = () => (
        editFlag?setEditFlag(false):null
    )
    const viewAdd = () =>(
        addFlag?setAddFlag(false):setAddFlag(true)
    )
    const viewEdit = () =>(
        editFlag?setEditFlag(false):setEditFlag(true)
    )

    let [food, setFood] = useState([]);

    useEffect(() => {
      fetchFood();
    }, []);
  
    let fetchFood = async () => {
      let { data } = await apis.get("restaurant/food", {
        headers: {
          "Content-Type": "application/json",
          Token: localStorage.getItem("jwt_res"),
        },
      });
    //   console.log(data.foods);
      setFood(data.foods);
    };
    

  return (
    <div className='menuchange-main'>
        <div onClick={() => {
          viewAdd();
          checkEditFlag();
        }} className='settings-heading'>{
            addFlag?<i className="fa-solid fa-caret-down"></i>:<i className="fa-solid fa-caret-right"></i>
        }Add new food (Request)</div>
        {
            addFlag?<AddItem />:null
        }
        <div onClick={() => {
            viewEdit();
            checkAddFlag();
        }} className='settings-heading'>{
            editFlag?<i className="fa-solid fa-caret-down"></i>:<i className="fa-solid fa-caret-right"></i>
        }Change existing food details</div>
        {
            editFlag?<EditItem food={food} />:null
        }
    </div>
  )
}

export default MenuChange