import React, { useEffect, useState } from "react";
// import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./TimeChanges.scss";
import apis from "../../components/axios/axios";
import { to24hr } from "../../services/to24hr";

const TimeChange = () => {
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState(null);

  let [days, setDays] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });

  useEffect(() => {
    fetchData();
  }, []);

  let fetchData = async () => {
    let { data } = await apis.get("restaurant/timings", {
      headers: {
        "Content-Type": "application/json",
        Token: localStorage.getItem("jwt_res"),
      },
    });
    console.log(data.timings);
    setFormData({
      closing_time: data.timings.closing_time,
      opening_time: data.timings.opening_time,
    });
    setDays({
      Mon: data.timings.days_open.includes("Monday"),
      Tue: data.timings.days_open.includes("Tuesday"),
      Wed: data.timings.days_open.includes("Wednesday"),
      Thu: data.timings.days_open.includes("Thursday"),
      Fri: data.timings.days_open.includes("Friday"),
      Sat: data.timings.days_open.includes("Saturday"),
      Sun: data.timings.days_open.includes("Sunday"),
    });
  };

  let onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let onFormSubmit = async (e) => {
    e.preventDefault();
    setSuccess(true);

    try {
      await apis.put(
        "restaurant/timings",
        {
          ...formData,
          days_open: [
            ...(days.Mon ? ["Monday"] : []),
            ...(days.Tue ? ["Tuesday"] : []),
            ...(days.Wed ? ["Wednesday"] : []),
            ...(days.Thu ? ["Thursday"] : []),
            ...(days.Fri ? ["Friday"] : []),
            ...(days.Sat ? ["Saturday"] : []),
            ...(days.Sun ? ["Sunday"] : []),
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            Token: localStorage.getItem("jwt_res"),
          },
        }
      );

      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  if (!formData) return;

  return (
    <form onSubmit={onFormSubmit}>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="settings-time">
            <TextField
              name="opening_time"
              label="Opening Time"
              InputLabelProps={{ shrink: true, required: true }}
              type="time"
              onChange={onInputChange}
              defaultValue={to24hr(formData.opening_time)}
            />
            <TextField
              name="closing_time"
              label="Closing Time"
              InputLabelProps={{ shrink: true, required: true }}
              type="time"
              onChange={onInputChange}
              defaultValue={to24hr(formData.closing_time)}
            />
          </div>
        </LocalizationProvider>
        <div className="settings-openon-heading">Working Days</div>
        <FormGroup>
          <div className="settings-days">
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.Mon}
                  onChange={() => setDays((pd) => ({ ...pd, Mon: !pd.Mon }))}
                />
              }
              label="Monday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.Tue}
                  onChange={() => setDays((pd) => ({ ...pd, Tue: !pd.Tue }))}
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.Wed}
                  onChange={() => setDays((pd) => ({ ...pd, Wed: !pd.Wed }))}
                />
              }
              label="Wednesday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.Thu}
                  onChange={() => setDays((pd) => ({ ...pd, Thu: !pd.Thu }))}
                />
              }
              label="Thursday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.Fri}
                  onChange={() => setDays((pd) => ({ ...pd, Fri: !pd.Fri }))}
                />
              }
              label="Friday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.Sat}
                  onChange={() => setDays((pd) => ({ ...pd, Sat: !pd.Sat }))}
                />
              }
              label="Saturday"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.Sun}
                  onChange={() => setDays((pd) => ({ ...pd, Sun: !pd.Sun }))}
                />
              }
              label="Sunday"
            />
          </div>
        </FormGroup>
        <div className="settings-time-btn">
          <Button
            type="submit"
            sx={{
              backgroundColor: "#485861",
              "&:hover": { backgroundColor: "black" },
            }}
            variant="contained"
          >
            {success ? "Saving..." : "Save Changes"}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default TimeChange;
