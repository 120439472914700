import React, { useState } from "react";
import "./resetPass.scss";
import { Button, TextField, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import apis from "../../components/axios/axios";
// import { useState } from 'react'

const ResetPass = () => {
  const navigate = useNavigate();

  // const [passMatch, setPassMatch] = useState(true)
  const [success, setSuccess] = useState(false);

  let onFormSubmit = async (e) => {
    e.preventDefault();
    setSuccess(true);
    if (pass !== confirm) alert("Passwords dont match!");
    else {
      // console.log(email);
      let { data } = await apis.put(
        "restaurant/password/reset",
        {
          password: pass,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Token: localStorage.getItem("jwt_res"),
          },
        }
      );
      console.log(data);
      navigate("/");
    }
  };

  let [pass, setPass] = useState("");
  let [confirm, setConfirm] = useState("");

  return (
    <div className="reset-main">
      <div className="reset-sub">
        <div className="brand">Eatplek Hotel Partner</div>
        <form onSubmit={onFormSubmit}>
          <Stack spacing={2}>
            <TextField
              type={"password"}
              label="New password"
              required
              variant="outlined"
              name="pass"
              onChange={(e) => setPass(e.target.value)}
            />
            <TextField
              type={"password"}
              label="Confirm new password"
              required
              variant="outlined"
              name="confirm"
              onChange={(e) => setConfirm(e.target.value)}
            />
            {
              // passMatch? <p></p> :<p className='password-match'>* passwords does not match</p>
            }
            <Button
              sx={{
                padding: "13px",
                backgroundColor: "#17202d",
                "&:hover": {
                  backgroundColor: "#0F3D3E",
                },
              }}
              type="submit"
              variant="contained"
            >
              {!success ? "RESET PASSWORD" : "Resetting..."}
            </Button>
          </Stack>
        </form>
      </div>
    </div>
  );
};

export default ResetPass;
