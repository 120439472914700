import React, { useEffect, useState } from "react";
// import CategoryOptions from './CategoryOptions';
// import IndividualOrder from './IndividualOrder';
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import "./Orders.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import OrderOptions from "./OrderOptions";
import apis from "../components/axios/axios";

let statusConfig = {
  "-1": "Pending",
  0: "Delivered",
  1: "Delay",
};

const Orders = () => {
  // const [categoryVisible, setVisibility] = useState(false);

  // const [category, setCategory] = useState("All");
  // const [displayCategory, setDisplay] = useState(false);
  // const showCategory = () =>{
  //     categoryVisible?hideCategory():viewCategory();
  //     displayCategory?setDisplay(false):setDisplay(true);
  //     console.log(displayCategory);
  // }
  // const hideCategory=()=>{
  //     setVisibility(false);
  // }
  // const viewCategory=()=>{
  //     setVisibility(true);
  // }

  let [orders, setOrders] = useState(null);
  let [filOrders, setFilOrders] = useState(null);

  useEffect(() => {
    fetchOrders();
  }, []);

  let fetchOrders = async () => {
    let { data } = await apis.get(
      "order/filter/restaurant/" + localStorage.getItem("jwt_res_id"),
      {
        headers: {
          "Content-Type": "application/json",
          Token: localStorage.getItem("jwt_res"),
        },
      }
    );
    // console.log(data.result);
    setOrders(data.result);
    setFilOrders(data.result);
  };
  const navigate = useNavigate();
  const navigateToInvoice = (id) => {
    navigate("/invoice/".concat(id));
  };

  let onSelect = (e) => {
    console.log(e.target.value);
    if (e.target.value == "all") {
      setFilOrders(orders);
    } else setFilOrders(orders.filter((i) => i.status == e.target.value));
  };

  let done = async (id) => {
    let { data } = await apis.put(
      "order/status",
      {
        id: id,
        status: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Token: localStorage.getItem("jwt_res"),
        },
      }
    );
    console.log(data);
    window.location.reload();
  };

  return (
    <div className="orders-main">
      <div className="order-category">
        <div>
          {/* <div onClick={showCategory} className='selected-category'>{category} <i className="fa-solid fa-angle-down"></i></div>
                    {
                        categoryVisible?<CategoryOptions setCategory={setCategory} setVisibility={setVisibility} />:null
                    } */}
          <FormControl sx={{ width: "10rem" }}>
            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Filter"
              onChange={onSelect}
              // sx={{zIndex: 1}}
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={-1}>Pending</MenuItem>
              <MenuItem value={0}>Delivered</MenuItem>
              <MenuItem value={1}>Delay</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="order-category-bottom">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Order</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Phone</TableCell>
                <TableCell align="center">No. of Guests</TableCell>
                <TableCell align="center">Invoice</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right">
                  <i className="fa-solid fa-gear"></i>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filOrders &&
                filOrders.map((row, i) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center" component="th" scope="row">
                      {++i}
                    </TableCell>
                    <TableCell align="center">{row.user.name}</TableCell>
                    <TableCell align="center">{row.user.phone}</TableCell>
                    <TableCell align="center">
                      {row.cart.number_of_guests}
                    </TableCell>
                    <TableCell align="center view-invoice">
                      <div onClick={() => navigateToInvoice(row.id)}>View</div>
                    </TableCell>
                    <TableCell align="center">
                      {statusConfig[row.status]}
                    </TableCell>
                    <TableCell align="right">
                      {/* <OrderOptions e={row} /> */}
                      {row.status !== 0 && (
                        <button
                          className="mark-as-done-btn"
                          onClick={() => done(row.id)}
                          style={{ cursor: "pointer" }}
                        >
                          Done <i className="fa-solid fa-check"></i>
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Orders;
