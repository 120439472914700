import React from "react";
import { Link } from "react-router-dom";
import "./OrderExpenses.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { localTime } from "../../services/localTime";
import { localDate } from "../../services/localDate";

const OrderExpenses = ({ data }) => {
  if (!data || !data.Orders) return;
  return (
    <div className="expenses-order-main">
      {/* <div className="expenses-orders expenses-heading">
            <div>Name</div>
            <div>Phone</div>
            <div>Date</div>
            <div>No of items</div>
            <div>Amount Paid</div>
            <div>More Info.</div>
        </div>
        <div className="expenses-sub">
        {
            order.map((e)=>
                <div key={e.id} className="expenses-orders">
                    <div>{e.customer}</div>
                    <div>{e.phone}</div>
                    <div>{e.date}</div>
                    <div>{e.items}</div>
                    <div>₹ {e.amount}</div>
                    <Link to="/invoice">
                        <div className='expenses-bill'>view bill</div>
                    </Link>
                </div>
            )
        }
        </div> */}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Phone</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">No. of Items</TableCell>
              <TableCell align="center">Amount Paid</TableCell>
              <TableCell align="right">More Info.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.Orders.map((row, i) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="center">{row.phone}</TableCell>
                <TableCell align="center">
                  {localDate(row.createdat) + "  " + localTime(row.createdat)}
                </TableCell>
                <TableCell align="center">{row.numberofitems}</TableCell>
                <TableCell align="center">₹ {row.totalamount}</TableCell>
                <TableCell align="right">
                  <Link to={`/invoice/${row.id}`}>
                    <div className="expenses-bill">View Bill</div>
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OrderExpenses;
