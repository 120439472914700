export const to24hr = (time12h) => {
  const [time, modifier] = time12h.split(" ");

  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }

  let res = `${hours}:${minutes}`;

  if (res.length < 5) return "0" + res;

  return res;
};
