import React, { useState } from "react";
import { Switch } from "@mui/material";
import "./InventoryFood.scss";
import InventoryFoodPopUp from "./InventoryFoodPopUp";
import apis from "../../components/axios/axios";

const InventoryFood = ({ foodName, available, id }) => {
  const [foodPopup, setFoodPopup] = useState(false);

  let [active, setActive] = useState(available);
  let onToggleAvailability = async () => {
    setActive(!active);
    let res = await apis.put(
      "food/availability/" + id,
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Token: localStorage.getItem("jwt_res"),
        },
      }
    );
    window.location.reload();
    console.log(res);
  };
  return (
    <div className="inventory-items">
      <div onClick={() => setFoodPopup(true)} className="inventory-food-btn">
        <i className="fa-solid fa-circle-notch"></i> {foodName}
      </div>
      <div className="inventory-toggle-main">
        <Switch checked={available} onChange={onToggleAvailability} />
      </div>
      <InventoryFoodPopUp
        trigger={foodPopup}
        setTrigger={setFoodPopup}
        available={available}
      />
    </div>
  );
};

export default InventoryFood;
