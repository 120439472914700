import React, { useState } from "react";
import "./HotelStatusPopUp.scss";
import { Button } from "@mui/material";
import apis from "./axios/axios";

const HotelStatusPopUp = ({ trigger, setTrigger }) => {
  const [success, setSuccess] = useState(false);

  const hotel = async () => {
    setSuccess(true);
    try {
      await apis.put(
        "restaurant/status/" + localStorage.getItem("jwt_res_id"),
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Token: localStorage.getItem("jwt_res"),
          },
        }
      );
      localStorage.setItem(
        "jwt_res_open",
        localStorage.getItem("jwt_res_open") === "true" ? "false" : "true"
      );
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  return trigger ? (
    <div className="hotel-status-popup-main">
      <div className="hotel-status-popup">
        <div
          onClick={() => setTrigger(false)}
          className="hotel-status-popup-close-btn"
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <div className="hotel-status-popup-sub">
          <div className="hotel-status-popup-text">
            Change Hotel Status to{" "}
            {localStorage.getItem("jwt_res_open") === "true"
              ? "Closed"
              : "Open"}
            ?
          </div>
          <div className="hotel-status-popup-btn">
            <Button
              onClick={hotel}
              color={
                localStorage.getItem("jwt_res_open") === "true"
                  ? "error"
                  : "success"
              }
              variant="contained"
            >
              {success ? "Changing..." : "Confirm"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default HotelStatusPopUp;
