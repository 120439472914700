import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA_FMJWmbm8afZJoAx7S2e1VlHSeaWOacg",
  authDomain: "eatplek-admin.firebaseapp.com",
  projectId: "eatplek-admin",
  storageBucket: "eatplek-admin.appspot.com",
  messagingSenderId: "436360147239",
  appId: "1:436360147239:web:6028ab733df85b79f0b902",
  measurementId: "G-XNBV6R633P",
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BE5vGCQIyqcOtX4x83wGUsL4ThCJACH7GnDi3aIabwPm5ezRKHbXyw2_Z239JBWyMXd5_w1Xxvz4Z033lJqLeyE",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        localStorage.setItem("token", currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
