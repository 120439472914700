import React from 'react'
// import HotelStatus from '../../components/HotelStatus'
// import Navbar from '../../components/Navbar'
import SettingsBottom from './SettingsBottom'

const Settings = () => {
  return (
    <div>
        {/* <Navbar />
        <HotelStatus />  */}
        <SettingsBottom />
    </div>
  )
}

export default Settings