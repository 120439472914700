import React, { useEffect } from "react";
import "./Profile.scss";
import { Button, Box } from "@mui/material";
import { useState } from "react";
import EditProfilePopUp from "./EditProfilePopUp";
import apis from "../../components/axios/axios";

const Profile = () => {
  let [trigger, setTrigger] = useState(false);

  let [dtl, setDtl] = useState(null);

  useEffect(() => {
    fetchDtl();
  }, []);

  let fetchDtl = async () => {
    let { data } = await apis.get("restaurant/profile", {
      headers: {
        "Content-Type": "application/json",
        Token: localStorage.getItem("jwt_res"),
      },
    });
    // console.log(data.profile);
    setDtl(data.profile);
  };

  if (!dtl) return;

  return (
    <div className="profile">
      <form action="" method="post">
        {/* <div className='menu-label'>Hotel Name</div>
            <div><input type="text" /></div>
            <div className='menu-label'>Phone No.</div>
            <div><input type="number" name="" id="" /></div>
            <div className='menu-label'>Email</div>
            <div><input type="email" /></div>    
            <div className='menu-label'>Location</div>
            <div><input type="text" /></div>
            <button className='profile-btn' type='submit'>Save</button> */}
        <div className="profile-main">
          {/*  */}
          <div className="profile-sub">
            <div>Name </div>
            <div>: {dtl.name}</div>
            <div>Phone No. </div>
            <div>: {dtl.phone}</div>
            <div>Email </div>
            <div>: {dtl.email}</div>
            <div>Location </div>
            <div>: {dtl.location}</div>
          </div>
          <Box>
            <Button
              onClick={() => setTrigger(true)}
              sx={{
                backgroundColor: "#485861",
                "&:hover": { backgroundColor: "black" },
              }}
              variant="contained"
            >
              EDIT PROFILE
            </Button>
          </Box>
        </div>
      </form>
      <EditProfilePopUp trigger={trigger} setTrigger={setTrigger} dtl={dtl} />
    </div>
  );
};

export default Profile;
