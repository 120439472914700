import React, { useEffect, useState } from "react";
import "./ExpensesBottom.scss";
import OrderExpenses from "./OrderExpenses";
import RevenueCard from "./RevenueCard";
// import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
// import TextField from '@mui/material'
// import dayjs from "dayjs";
import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import apis from "../../components/axios/axios";

const ExpensesBottom = () => {
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    start_date: new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000),
    end_date: new Date(),
  });
  const [data, setData] = useState(null);

  useEffect(() => {
    initialRevenue();
  }, []);

  let initialRevenue = async () => {
    let res = await apis.post(
      "hotel/revenue",
      {
        start_date: new Date(formData.start_date).toISOString(),
        end_date: new Date(formData.end_date).toISOString(),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Token: localStorage.getItem("jwt_res"),
        },
      }
    );
    console.log(res.data);
    setData(res.data);
  };

  let onFormSubmit = async (e) => {
    e.preventDefault();
    setSuccess(true);
    let res = await apis.post(
      "hotel/revenue",
      {
        start_date: new Date(formData.start_date).toISOString(),
        end_date: new Date(formData.end_date).toISOString(),
      },
      {
        headers: {
          "Content-Type": "application/json",
          Token: localStorage.getItem("jwt_res"),
        },
      }
    );
    console.log(res.data);
    setData(res.data);
    setSuccess(false);
  };

  let onInputChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="expenses-bottom-main">
      {/* <button className='expenses-btn'>By Day <i className="fa-solid fa-caret-down"></i></button> */}
      {/* <FormControl sx={{width:"10rem"}}>
              <InputLabel id="demo-simple-select-label">View By</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="View By"
              >
                <MenuItem>Day</MenuItem>
                <MenuItem>Week</MenuItem>
                <MenuItem>Month</MenuItem>
              </Select>
            </FormControl> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <form onSubmit={onFormSubmit}>
          <div className="expenses-btn-div">
            {/* <DesktopDatePicker
              label="From"
              inputFormat="MM/DD/YYYY"
              name="start_date"
              required
              renderInput={(params) => (
                <TextField
                  value={formData.start_date}
                  onChange={onInputChange}
                  {...params}
                />
              )}
            /> */}
            {/* <DesktopDatePicker
              label="To"
              inputFormat="MM/DD/YYYY"
              name="end_date"
              value={formData.end_date}
              onChange={onInputChange}
              renderInput={(params) => <TextField {...params} />}
            /> */}
            <TextField
              type="date"
              id="start"
              name="start_date"
              min="2022-08-06"
              max="2040-12-31"
              onChange={onInputChange}
              defaultValue={new Date(formData.start_date)
                .toISOString()
                .substr(0, 10)}
              required
            />
            <TextField
              type="date"
              id="end"
              label=""
              name="end_date"
              min="2022-08-06"
              max="2040-12-31"
              onChange={onInputChange}
              defaultValue={new Date(formData.end_date)
                .toISOString()
                .substr(0, 10)}
              required
            />
            <Button
              type="submit"
              sx={{ padding: "0.2rem 1rem" }}
              variant="contained"
            >
              {success ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </form>
      </LocalizationProvider>
      <RevenueCard data={data} />
      <OrderExpenses data={data} />
    </div>
  );
};

export default ExpensesBottom;
