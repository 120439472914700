import React from "react";
import { useRef, useState, useEffect } from "react";
import apis from "../../components/axios/axios";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { onMessageListener, requestForToken } from "../../firebase";

const Login = () => {
  requestForToken();
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("Error");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(true);
    try {
      let { data } = await apis.post("restaurant/login", {
        username: user,
        password: pwd,
      });
      // console.log(data);
      localStorage.setItem("jwt_res", data.result.token);
      localStorage.setItem("jwt_res_name", data.result.name);
      localStorage.setItem("jwt_res_id", data.result.id);
      localStorage.setItem("jwt_res_open", data.result.open);

      let res = await apis.patch(
        "restaurant/token",
        {
          device_token: localStorage.getItem("token")
        },
        {
          headers: {
            "Content-Type": "application/json",
            Token: localStorage.getItem("jwt_res"),
          },
        }
      );
      console.log(res);
      navigate("/");
    } catch (err) {
      // console.log(err);
      alert("Invalid Credentials!");
      setSuccess(false);
    }
  };

  return (
    <div className="main-login">
      <div className="login-page">
        <div className="form">
          <h1>Hotel Partner Login</h1>
          <form className="login-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="username"
              id="username"
              ref={userRef}
              autoComplete="off"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
            />
            <input
              type="password"
              placeholder="password"
              id="password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
              required
            />
            {/* <button onClick={navigateHome}>login</button> */}
            <button type="submit">{success ? "Logging In..." : "Login"}</button>
            <p
              ref={errRef}
              className={errMsg ? "errmsg" : "offscreen"}
              aria-live="assertive"
            >
              {errMsg}
            </p>
          </form>
          <Box mt={1} display="flex" justifyContent="flex-end">
            <Link to="/forgot">Forgot pass?</Link>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Login;
