import React, { useState } from "react";
import "./MenuChange.scss";
import {
  TextField,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import apis from "../../components/axios/axios";

const EditItem = ({ food }) => {
  // console.log(food);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    non_ac_price: 0,
    ac_price: 0,
    description: "",
  });

  let onFormSubmit = async (e) => {
    e.preventDefault();
    setSuccess(true);

    try {
      await apis.put(
        "restaurant/food",
        {
          ...formData,
          ac_price: Number(formData.ac_price),
          non_ac_price: Number(formData.non_ac_price),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Token: localStorage.getItem("jwt_res"),
          },
        }
      );

      window.alert("Food Updated Successfully!");
      setSuccess(false);
    } catch (e) {
      console.log(e);
    }
  };

  let onSelect = async (e) => {
    let item;
    for (let i = 0; i < food.length; i++) {
      if (food[i].id === e.target.value) {
        item = food[i];
      }
    }
    setFormData({
      id: item.id,
      name: item.name,
      non_ac_price: item.non_ac_price,
      ac_price: item.ac_price,
      description: item.description,
    });
  };

  let onInputChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="edit-item">
      <form onSubmit={onFormSubmit}>
        {/* <div className='menu-label'>Choose item</div>
            <div>
                <select name="" id="">Choose</select>
            </div>
            <div className='menu-label'>Item Name</div>
            <div><input className='item-name' type="text" /></div>
            <div className='menu-label'>Price</div>
            <div><input className='item-price' type="number" name="" id="" /></div>    
            <div className='menu-label'>Description</div>
            <div><textarea name="" id="" cols="50" rows="5"></textarea></div>
            <button className='menuchange-btn'  type='submit'>Save</button> */}
        <div className="edit-item-main">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Choose Food</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Choose Food"
              onChange={onSelect}
              required
            >
              {food && food.map((e, i) => {
                return (
                  <MenuItem key={i} value={e.id}>
                    {e.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="Item Name"
            name="name"
            variant="outlined"
            value={formData.name}
            onChange={onInputChange}
          />
          <TextField
            id="outlined-basic"
            label="Price (Non-AC)"
            type="number"
            variant="outlined"
            name="non_ac_price"
            onChange={onInputChange}
            value={formData.non_ac_price}
          />
          <TextField
            id="outlined-basic"
            label="Price (AC)"
            variant="outlined"
            type="number"
            name="ac_price"
            onChange={onInputChange}
            value={formData.ac_price}
          />
          <TextField
            multiline
            rows={3}
            id="outlined-basic"
            label="Description"
            variant="outlined"
            name="description"
            onChange={onInputChange}
            value={formData.description}
          />
          <Box>
            <Button
              sx={{
                backgroundColor: "#485861",
                "&:hover": { backgroundColor: "black" },
              }}
              type="submit"
              variant="contained"
            >
              {success ? "Saving..." : "Save"}
            </Button>
          </Box>
        </div>
      </form>
    </div>
  );
};

export default EditItem;
