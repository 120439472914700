import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import "./notifications.scss";
import apis from "../../components/axios/axios";

const Notifications = () => {
  let decline = async (id) => {
    let res = await apis.put(
      "cart/status",
      {
        userid: id,
        status: 1,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Token: localStorage.getItem("jwt_res"),
        },
      }
    );
    console.log(res);
    window.location.reload();
  };

  let accept = async (id) => {
    let res = await apis.put(
      "cart/status",
      {
        userid: id,
        status: 0,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Token: localStorage.getItem("jwt_res"),
        },
      }
    );
    console.log(res);
    window.location.reload();
  };

  const [requests, setRequests] = useState([]);

  let fetchRequests = async () => {
    let res = await apis.get("restaurant/requests", {
      headers: {
        "Content-Type": "application/json",
        Token: localStorage.getItem("jwt_res"),
      },
    });
    console.log(res);
    setRequests(res.data.requests);
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  return (
    <div className="noty-main">
      <h1>Notifications</h1>
      <div className="noty-sub">
        {requests && requests.map((item, index) => {
          return (
            <div className="noty" key={index}>
              <div>Order Type: </div>
              <div>{item.type}</div>
              <div>Time: </div>
              <div>{item.time}</div>
              <div>No. of Guest: </div>
              <div>{item.number_of_guests}</div>
              <div className="noty-btn">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => accept(item.user_id)}
                >
                  Accept
                </Button>
              </div>
              <div className="noty-btn">
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => decline(item.user_id)}
                >
                  Reject
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Notifications;
