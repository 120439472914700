import React, { useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import LogoutPopUp from './LogoutPopUp'
import MenuChange from './MenuChange'
import Profile from './Profile'
import './SettingsBottom.scss'
import TimeChange from './TimeChange'

const SettingsBottom = () => {
    const [profileFlag, setProfileFlag] = useState(true)
    const [menuFlag, setMenuFlag] = useState(false)
    const [timeFlag, setTimeFlag] = useState(false)
    const [logoutPopup, setLogoutPopup] = useState(false);

    const navigate = useNavigate();

    const changeProfileFlag = () => {
        setProfileFlag(true);
        setMenuFlag(false);
        setTimeFlag(false);
        navigate('/settings/profile')
    }

    const changeMenuFlag = () => {
        setMenuFlag(true);
        setProfileFlag(false);
        setTimeFlag(false);
        navigate('/settings/menuchange')
    }

    const changeTimeFlag = () => {
        setTimeFlag(true);
        setMenuFlag(false);
        setProfileFlag(false);
        navigate('/settings/timechange')
    }
    
  return (
    <div className='settings-bottom-main'>
        <div className="settings-bottom">
            <div className="settings-left">
                Settings
                <hr className='settings-left-hr'  align="left" />
                <div className="settings-left-sub">
                    <div className={profileFlag?"selected-category":null} onClick={changeProfileFlag}>Profile</div>
                    <div className={menuFlag?"selected-category":null} onClick={changeMenuFlag}>Menu Change</div>
                    <div className={timeFlag?"selected-category":null} onClick={changeTimeFlag}>Time Change</div>
                    <div onClick={()=>setLogoutPopup(true)}>Logout</div>
                </div>
            </div>
            <div className="settings-vr"></div>
            <div className="settings-right">
                {/* {profileFlag?<Profile />:null}
                {menuFlag?<MenuChange />:null}
                {timeFlag?<TimeChange />:null} */}
                
                <Routes>
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/menuchange' element={<MenuChange />} />
                    <Route path='/timechange' element={<TimeChange />} />
                </Routes>
            </div>
        </div>
        <LogoutPopUp trigger={logoutPopup} setTrigger={setLogoutPopup} />
    </div>
  )
}

export default SettingsBottom