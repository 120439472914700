import React from 'react'
// import HotelStatus from '../../components/HotelStatus'
// import Navbar from '../../components/Navbar'
import './Expenses.scss'
import ExpensesBottom from './ExpensesBottom'

const Expenses = () => {
  return (
    <div>
        {/* <Navbar />
        <HotelStatus /> */}
        <ExpensesBottom />
    </div>
  )
}

export default Expenses