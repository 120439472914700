import React from "react";
import "./LogoutPopUp.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LogoutPopUp = ({ trigger, setTrigger }) => {
  const navigate = useNavigate();
  let onLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  return trigger ? (
    <div className="logout-main">
      <div className="logout">
        {/* <div onClick={()=>setTrigger(false)} className="logout-popup-close-btn">
                <i className="fa-solid fa-xmark"></i>
            </div> */}
        <div className="logout-heading">Are you sure you want to logout?</div>
        <div className="logout-btns">
          <Button onClick={() => setTrigger(false)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={onLogout} color="error" variant="contained">
            Confirm
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default LogoutPopUp;
