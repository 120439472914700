import React from 'react'
// import Navbar from '../../components/Navbar'
// import HotelStatus from '../../components/HotelStatus'
import './Inventory.scss'
import InventoryBottom from './InventoryBottom'

const Inventory = () => {
  return (
    <div>
        {/* <Navbar />
        <HotelStatus /> */}
        <InventoryBottom />
    </div>
  )
}

export default Inventory