import React, { useState } from "react";
import "./EditProfilePopUp.scss";
import { Button, Box, TextField } from "@mui/material";
import apis from "../../components/axios/axios";

const EditProfilePopUp = ({ trigger, setTrigger, dtl }) => {
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: dtl.name,
    phone: dtl.phone,
    email: dtl.email,
    location: dtl.location,
  });

  let onInputChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let onFormSubmit = async (e) => {
    setSuccess(true);
    e.preventDefault();
    // console.log(formData);
    await apis.put("restaurant/profile", formData, {
      headers: {
        "Content-Type": "application/json",
        Token: localStorage.getItem("jwt_res"),
      },
    });
    window.location.reload();
  };

  return trigger ? (
    <div className="edit-profile-popup-main">
      <div className="edit-profile-popup">
        <div
          onClick={() => setTrigger(false)}
          className="edit-profile-popup-close-btn"
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <form onSubmit={onFormSubmit}>
          <div className="edit-profile-popup-sub">
            <h1 className="edit-pro-heading">Edit Profile</h1>

            <TextField
              type="text"
              id="outlined-basic"
              label="Name"
              variant="outlined"
              value={formData.name}
              name="name"
              InputLabelProps={{ shrink: true }}
              onChange={onInputChange}
            />
            <TextField
              type="number"
              id="outlined-basic"
              label="Phone No."
              variant="outlined"
              value={formData.phone}
              name="phone"
              InputLabelProps={{ shrink: true }}
              onChange={onInputChange}
            />
            <TextField
              type="email"
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={formData.email}
              name="email"
              InputLabelProps={{ shrink: true }}
              onChange={onInputChange}
            />
            <TextField
              type="text"
              id="outlined-basic"
              label="Location."
              variant="outlined"
              value={formData.location}
              name="location"
              InputLabelProps={{ shrink: true }}
              onChange={onInputChange}
            />
            <Box>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "#485861",
                  "&:hover": { backgroundColor: "black" },
                }}
                variant="contained"
              >
                {success ? "Saving..." : "Save Changes"}
              </Button>
            </Box>
          </div>
        </form>
      </div>
    </div>
  ) : null;
};

EditProfilePopUp.defaultProps = {
  dtl: {
    name: "",
    phone: "",
    email: "",
    location: "",
  },
};

export default EditProfilePopUp;
