import React, { useState } from "react";
import "./MenuChange.scss";
import { TextField, Button, Box } from "@mui/material";
import apis from "../../components/axios/axios";

const AddItem = () => {
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState(null);

  let onFormSubmit = async (e) => {
    e.preventDefault();
    setSuccess(true);

    try {
      await apis.post(
        "request",
        {
          ...formData,
          price: Number(formData.price),
          restaurant: localStorage.getItem("jwt_res_name"),
          restaurant_id: localStorage.getItem("jwt_res_id"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Token: localStorage.getItem("jwt_res"),
          },
        }
      );
    } catch (e) {
      console.log(e);
    }

    window.alert("Request Sent Successfully!");
    setSuccess(false);
  };

  let onInputChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="add-item">
      <form onSubmit={onFormSubmit}>
        {/* <div className='menu-label'>Item Name</div>
            <div><input className='item-name' type="text" /></div>
            <div className='menu-label'>Price</div>
            <div><input className='item-price' type="number" name="" id="" /></div>    
            <div className='menu-label'>Description</div>
            <div><textarea name="" id="" cols="50" rows="5"></textarea></div>
            <button className='menuchange-btn' type='submit'>Send Request</button> */}
        <div className="add-item-main">
          <TextField
            id="outlined-basic"
            type="text"
            label="Item Name"
            variant="outlined"
            name="name"
            onChange={onInputChange}
          />
          <TextField
            id="outlined-basic"
            type="number"
            label="Price"
            variant="outlined"
            name="price"
            onChange={onInputChange}
          />
          <TextField
            multiline
            rows={3}
            id="outlined-basic"
            label="Description"
            variant="outlined"
            name="description"
            onChange={onInputChange}
          />
          <Box>
            <Button
              sx={{
                backgroundColor: "#485861",
                "&:hover": { backgroundColor: "black" },
              }}
              type="submit"
              variant="contained"
            >
              {success ? "Sending..." : "Send"}
            </Button>
          </Box>
        </div>
      </form>
    </div>
  );
};

export default AddItem;
