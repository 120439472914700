import React from "react";
import "./RevenueCard.scss";
const RevenueCard = ({ data }) => {
  return (
    <div className="revenue-card-main">
      <div>
        <div>Total Revenue</div>
        <div className="revenue-total">₹ {data ? data.Total : 0}</div>
      </div>
      <div>
        <div>Total Orders</div>
        <div className="revenue-card-order">
          {data && data.Orders ? data.Orders.length : 0}
        </div>
      </div>
    </div>
  );
};

export default RevenueCard;
